import React from "react"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { Container } from "reactstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CheckoutForm from "../components/checkout/CheckoutForm"

const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY)

const SewingClassesPage = () => {
  return (
    <Layout location="checkout">
      <SEO title="Checkout" />
      <Container>
        <h2 style={{ width: "100%", textAlign: "center" }}>Checkout</h2>
        <Elements stripe={stripePromise}>
          <div className="stripe-elements">
            <CheckoutForm />
          </div>
        </Elements>
      </Container>
    </Layout>
  )
}

export default SewingClassesPage
